<template>
  <div>
    <v-card elevation="12">
      <v-card-text>
        <h1>Újdonságok</h1>
        <p>Több csoportvezető egy csoportban</p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'tipsAndNews',
  data () {
    return {

    }
  },
}
</script>
