import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'
import roles from '../plugins/roles.js'
import axios from 'axios'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      minUserRole: roles.unauthenticated,
      maxUserRole: roles.unauthenticated,
      name: 'Kezdőlap',
      showInMenu: true,
      icon: 'fa-home'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/loginPage.vue'),
    meta: {
      minUserRole: roles.unauthenticated,
      maxUserRole: roles.unauthenticated,
      name: 'Bejelentkezés',
      showInMenu: true,
      icon: 'fa-sign-in-alt'
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/profile.vue'),
    meta: {
      minUserRole: roles.parent,
      name: 'Felhasználói profil',
      showInMenu: true,
      icon: 'fa-user-edit'
    },
  },

  {
    path: '/groups',
    name: 'groups',
    component: () => import('../components/groups.vue'),
    meta: {
      minUserRole: roles.trainer,
      name: "Csoportok",
      showInMenu: true,
      icon: 'fa-users'
    }
  },
  {
    path: '/group/:id',
    name: 'group',
    component: () => import('../views/group.vue'),
    meta: {
      minUserRole: roles.trainer,
      showInMenu: false,
    }
  },


  {
    path: '/kidAthleteManagement',
    name: 'kidAthleteManagement',
    component: () => import('../views/kidAthleteManagement.vue'),
    meta: {
      minUserRole: roles.clubAdmin,
      name: "Kölyökatléták kezelése",
      showInMenu: true,
      icon: 'fa-child'
    }
  },

  {
    path: '/exercises',
    name: 'exercises',
    component: () => import('../views/exercises.vue'),
    meta: {
      minUserRole: roles.trainer,
      name: "Gyakorlatok",
      showInMenu: true,
      icon: 'fa-dumbbell'
    }
  },
  {
    path: '/exercise/:id',
    name: 'exercise',
    component: () => import('../views/exercise.vue'),
    meta: {
      minUserRole: roles.trainer,
      showInMenu: false
    }
  },

  {
    path: '/settings/exercises',
    name: 'exercisesSettings',
    component: () => import('../views/exercisesSettings.vue'),
    meta: {
      minUserRole: roles.nationalCoordinator,
      name: "Gyakorlatok beállításai",
      showInMenu: true,
      icon: 'fa-cog'
    }
  },



  {
    path: '/trainingplans',
    name: 'trainingPlans',
    component: () => import('../views/trainingPlans.vue'),
    meta: {
      minUserRole: roles.trainer,
      name: "Edzéstervek",
      showInMenu: true,
      icon: 'fa-clipboard-list'
    }
  },
  {
    path: '/trainingplan/:id',
    name: 'trainingPlan',
    component: () => import('../views/trainingPlan.vue'),
    meta: {
      minUserRole: roles.trainer,
      showInMenu: false
    }
  },

  {
    path: '/users',
    name: 'users',
    component: () => import('../components/users.vue'),
    meta: {
      minUserRole: roles.trainer,
      name: 'Felhasználók',
      showInMenu: true,
      icon: 'fa-user-cog'
    }
  },
  {
    path: '/user/:id',
    name: 'user',
    component: () => import('../views/user.vue'),
    meta: {
      minUserRole: roles.trainer,
      showInMenu: false,
    }
  },

  {
    path: '/regions',
    name: 'regions',
    component: () => import('../views/regions.vue'),
    meta: {
      minUserRole: roles.nationalCoordinator,
      name: 'Régiók',
      showInMenu: true,
      icon: 'fa-map-marked'
    }
  },

  {
    path: '/clubs',
    name: 'clubs',
    component: () => import('../components/clubs.vue'),
    meta: {
      minUserRole: roles.regionalCoordinator,
      name: 'Egyesületek',
      showInMenu: true,
      icon: 'fa-map-marker'
    }
  },
  {
    path: '/club/:id',
    name: 'club',
    component: () => import('../views/club.vue'),
    meta: {
      minUserRole: roles.regionalCoordinator,
      showInMenu: false,
    }
  },


  {
    path: '/contractPeriods',
    name: 'contractPeriods',
    component: () => import('../components/contractPeriods.vue'),
    meta: {
      minUserRole: roles.regionalCoordinator,
      name: 'Szerződési időszakok',
      showInMenu: true,
      icon: 'fa-file-signature'
    }
  },

  {
    path: '/contractPeriod/:id',
    name: 'contractPeriod',
    component: () => import('../views/contractPeriod.vue'),
    meta: {
      minUserRole: roles.regionalCoordinator,
      showInMenu: false
    }
  },

  /*{
    path: '/competition/calendar',
    name: 'competitionCalendar',
    component:  () => import('../views/competitionCalendar.vue'),
    meta: {
      minUserRole: roles.trainer,
      showInMenu: true,
      name: "Versenynaptár",
      icon: 'fa-calendar'
    }
  },*/
  {
    path: '/competition/list',
    name: 'competitionList',
    component:  () => import('../views/competitionList.vue'),
    meta: {
      minUserRole: roles.trainer,
      showInMenu: true,
      name: "Versenyek (nevezés/eredmények)",
      icon: 'fa-hand-paper'
    }
  },
  {
    path: '/competition/coordinatorList',
    name: 'competitionListForCoordinator',
    component:  () => import('../views/competitionListForCoordinator.vue'),
    meta: {
      minUserRole: roles.regionalCoordinator,
      showInMenu: true,
      name: "Koordinátori verseny felület",
      icon: 'fa-user-tie'
    }
  },
  {
    path: '/competition/info/:id',
    name: 'competitionInfo',
    component:  () => import('../views/competitionInfo.vue'),
    meta: {
      minUserRole: roles.trainer,
      showInMenu: false,
    }
  },

  {
    path: '/competition/organizer',
    name: 'organizedCompetitions',
    component:  () => import('../views/organizedCompetitions.vue'),
    meta: {
      minUserRole: roles.trainer,
      showInMenu: true,
      name: "Versenyszervezés",
      icon: 'fa-trophy'
    }
  },

  {
    path: '/competition/organize/:id/:coordinatorView?',
    name: 'competitionOrganizer',
    component:  () => import('../views/competitionOrganizer.vue'),
    meta: {
      minUserRole: roles.trainer,
      showInMenu: false,
    }
  },

  {
    path: '/competition/helper',
    name: 'competitionHelper-ExerciseList',
    component:  () => import('../views/competitionHelper-exerciseList.vue'),
    meta: {
      minUserRole: roles.competitionHelper,
      showInMenu: true,
      name: "Eredményrögzítés",
      icon: 'fa-user-clock'
    }
  },




  {
    path: '/statistics',
    name: 'statistics',
    component: () => import('../views/statistics.vue'),
    meta: {
      minUserRole: roles.regionalCoordinator,
      showInMenu: true,
      name: "Statisztikák",
      icon: 'fa-table'
    }
  },

  {
    path: '/competition/EMUCResultLog/:EMUCId',
    name: 'competition-resultLog',
    component:  () => import('../components/competition-resultLog.vue'),
    meta: {
      minUserRole: roles.competitionHelper,
      showInMenu: false,
    },
    props: true
  },

  {
    path: '/test/:id',
    name: 'test',
    component:  () => import('../views/test.vue'),
    meta: {
      minUserRole: roles.parent,
      showInMenu: false,
    }
  },


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const checkAuthStatus = function () {
    return new Promise((resolve, reject) => {
      if(store.getters.authStatus=="unchecked") {
        axios({url: 'user', method: 'GET', params: {full: true}}).then((response) => {
          store.commit('checkAuthStatus', response.data.data)
          resolve()
        })
        .catch((error)=> {
          store.commit('checkAuthStatus', {})
          resolve()
        })
      }
      else resolve();
    })
  }
  checkAuthStatus().then(() => {
    if(to.meta.minUserRole<=store.getters.userRole) next()
    else next('/login')
  })

})

export default router
