module.exports = []

module.exports[9999] = "Ismeretlen hiba történt"
module.exports[500] = module.exports[9999]

module.exports[404] = "A funkció vagy a keresett oldal nem elérhető."
module.exports[1001] = "Nem megfelelő bejelentkezési adatokat adott meg. Kérem próbálja újra!"
module.exports[1002] = "Az oldal megtekintéséhez kérem jelentkezzen be!"
module.exports[401] = module.exports[1002];
module.exports[1003] = module.exports[1001]; // Hiányzó adatok
module.exports[1004] = "Az oldal megtekintéséhez nincs joga!"
module.exports[1005] = "A kliens hibás kérést küldött"
module.exports[400] = module.exports[1005];
module.exports[1006] = "Helytelen adatokat adott meg!"
module.exports[1007] = "A példány nem található"
module.exports[1008] = "Már létezik!"
module.exports[1009] = "Hiba történt a \"Nem vagyok robot\" próba ellenőrzésekor!"

module.exports[403] = "Nem engedélyezett művelet"
